import "../styles/Footer.css";

import React, { useEffect, useMemo, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import GooglePlay from "../assets/icones/google_play.png";
import AppStore from "../assets/icones/app_store.png";
import Rating from "@mui/material/Rating";

import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import axios from "axios";

function Footer({ showMentions, setShowMentions }) {
  const toggle = () => setShowMentions(!showMentions);
  const forceDisplay = false;

  const [isAndroid, setIsAndroid] = useState(forceDisplay);
  const [isIOS, setIsIOS] = useState(forceDisplay);
  const isMobile = useMemo(() => getMobileOsName(), []);

  var touchDevice =
    navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

  const [googleRating, setGoogleRating] = useState(-1);
  const [appleRating, setAppleRating] = useState(-1);
  const [error, setError] = useState(null);

  const fetchGooglePlayRating = async () => {
    console.log("test");
    try {
      const response = await axios.get(
        `https://app.leco-france.eu:3001/google-play-rating/io.ionic.maxis`
      );
      if (
        response &&
        response.data &&
        response.data.rating &&
        response.data.rating > 0
      )
        setGoogleRating(response.data.rating.toFixed(1));
      else setGoogleRating(-1);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchAppStoreRating = async () => {
    try {
      const response = await axios.get(
        `https://app.leco-france.eu:3001/app-store-rating/1548527789`
      );
      if (
        response &&
        response.data &&
        response.data.rating &&
        response.data.rating > 0
      )
        setAppleRating(response.data.rating.toFixed(1));
      else setAppleRating(-1);
    } catch (err) {
      setError(err.message);
    }
  };

  // Fonction that update co2 and gasoil
  useEffect(() => {
    if (true) {
      var isAndroidTmp = forceDisplay && isMobile.Android();
      var isIOSTmp = forceDisplay && isMobile.iOS();
      setIsAndroid(isAndroidTmp || (!isAndroidTmp && !isIOSTmp));
      setIsIOS(isIOSTmp || (!isAndroidTmp && !isIOSTmp));

      console.log("isAndroidTmp", isAndroidTmp);
      if (isAndroidTmp || (!isAndroidTmp && !isIOSTmp)) {
        fetchGooglePlayRating();
      }
      if (isIOSTmp || (!isAndroidTmp && !isIOSTmp)) {
        fetchAppStoreRating();
      }
    }
  }, [touchDevice, isMobile]);

  return (
    <div className="Footer">
      <div style={{ marginTop: "1.5rem" }} className="content">
        <div className="footer-content">
          <div className="left">
            <div className="left-item">
              Information
              <p>
                <a href="mailto:info@leco-france.eu">info@leco-france.eu</a>
              </p>
            </div>
            <div className="left-item">
              Partenariat
              <p>
                <a href="mailto:v.barremaecker@leco-france.eu">
                  v.barremaecker@leco-france.eu
                </a>
              </p>
            </div>
            <div className="left-item">
              <br />
              <br />
              <p>
                8 impasse Mathieu
                <br />
                75015 Paris
                <br />
                France
                <br />
              </p>
            </div>
          </div>
          <div className="right">
            <div className="link-item">
              <div className="link-name">Visitez</div>
              <div className="link-subname">notre page LinkedIn</div>
              <div className="link-icon">
                <a href="https://www.linkedin.com/company/lecofrance/">
                  <LinkedInIcon />
                </a>
              </div>
            </div>
            {(isAndroid || isIOS) && (
              <div className="link-item">
                <div className="link-name">Téléchargez</div>
                <div className="link-subname">l'application mobile</div>
                <div className="link-icon">
                  {isAndroid && (
                    <>
                      <a href="https://play.google.com/store/apps/details?id=io.ionic.maxis">
                        <img
                          className="google-play-img"
                          alt="leader-ico"
                          src={GooglePlay}
                        />
                      </a>
                      {googleRating > 0 && (
                        <div className="app-stars">
                          <span>{googleRating}</span>
                          <Rating
                            name="half-rating-read"
                            value={googleRating}
                            precision={0.1}
                            readOnly
                          />
                        </div>
                      )}
                    </>
                  )}
                  {isIOS && (
                    <>
                      <a href="https://apps.apple.com/fr/app/léco/id1548527789">
                        <img
                          className="apple-store-img"
                          alt="leader-ico"
                          src={AppStore}
                        />
                      </a>
                      {appleRating > 0 && (
                        <div className="app-stars">
                          <span>{appleRating}</span>
                          <Rating
                            name="half-rating-read"
                            value={appleRating}
                            precision={0.1}
                            readOnly
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          sm="9"
          className="copyright-item color-grey
        "
        >
          <p>
            <a
              href="http://wagon.o2switch.net:2096"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", width: "4rem", height: "3rem" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            Copyright © {new Date().getFullYear()}. Réalisé par{" "}
            <a
              style={{ textDecoration: "none", width: "3rem", height: "3rem" }}
              href="https://digibuddy.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              DigiBuddy.
            </a>{" "}
            Tous droits réservés.{" "}
            <span style={{ cursor: "pointer" }} onClick={toggle}>
              Mentions Légales.
              <br />
            </span>
          </p>
        </div>
      </div>

      <Modal className="modal-mentions-legales" isOpen={showMentions}>
        <ModalHeader>Mentions Légales</ModalHeader>
        <ModalBody>
          <table>
            <tr>
              <td className="ml-title">Entreprise</td>
            </tr>
            <tr>
              <td className="ml-subText">Léco - L' Éco-Conducteur</td>
            </tr>
            <tr>
              <td className="ml-subText">8 impasse mathieu - 75015 Paris</td>
            </tr>
            <tr>
              <td className="ml-subText">
                01 87 66 34 12 – info@leco-france.eu
              </td>
            </tr>
            <tr>
              <td className="ml-subText">RCS 947 515 813 DE PARIS</td>
            </tr>
            <tr>
              <td className="ml-subText">Président : Vincent Barremaecker</td>
            </tr>
            <tr>
              <td className="ml-subText">
                Directeur général : Vincent Deliencourt
              </td>
            </tr>

            <tr>
              <td className="ml-title">Hébergeur</td>
            </tr>
            <tr>
              <td className="ml-subText">
                SARL INULOGIC – 17 RUE CALMETTE 69800 ST PRIEST
              </td>
            </tr>
            <tr>
              <td className="ml-subText">RCS 519.881.981 DE LYON ACTIVITE</td>
            </tr>
            <tr>
              <td className="ml-title">Liens hypertextes</td>
            </tr>
            <tr>
              <td className="ml-subText">
                Léco ne peut être tenu responsable du contenu des sites ayant
                établi un lien hypertexte vers le site www.leco-france.eu.
              </td>
            </tr>
          </table>
        </ModalBody>

        <ModalFooter>
          <Button className="ml-butt-valid" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Footer;

function getMobileOsName() {
  return {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
    any: function() {
      return (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
        navigator.userAgent.match(/Opera Mini/i) ||
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
  };
}
