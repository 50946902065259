// CSS
import "../styles/Tarif.css";

import { Container } from "reactstrap";

import React, { useState, useEffect } from "react";

import IconConducteur from "../assets/icones/icon_appli_conducteur_sans_ombre.png";
import IconFormateur from "../assets/icones/icon_formateur_sans_ombre.png";
import IconTransporteur from "../assets/icones/icon_transporteur_sans_ombre.png";

function Tarif() {
  return (
    <Container className="tarif">
      <div className="content">
        <div className="logos-bar">
          <div className="logos-bar-item">
            <img src={IconTransporteur} alt="icon-transporteur" />
          </div>
          <div className="logos-bar-item">
            <img src={IconFormateur} alt="icon-formateur" />
          </div>
          <div className="logos-bar-item">
            <img src={IconConducteur} alt="icon-conducteur" />
          </div>
        </div>
        <div className="tarifs-unique">
          <div className="tarifs-unique-text">
            <div className="title color-green">Tarif Unique</div>
            <div className="subtitle color-blue">tout compris</div>
            <div className="text color-grey">
              Formation aux outils numériques incluse.
              <br />
              Forfait mensuel par utilisateur.
              <br />
              Sans engagement.
            </div>
            <div className="subtext color-grey">
              CRM Formateur gratuit pour les indépendants.
            </div>
          </div>
          <div className="tarifs-unique-prix">
            <div className="tarifs-unique-prix-item color-green">15€</div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Tarif;
